<template lang="pug">
div(class="table-wrapper")
  table
    thead(class="text-center")
      tr
        th(v-for="(item, index) in headers", :key="index" :style="{ width: item.width ? item.width + 'px' : 'auto' }")
          | {{ $t(item.locale) }}
    tbody(v-if="source.length > 0", class="text-center" v-loading="isLoading" element-loading-background="rgba(0, 0, 0, 0)")
      tr(v-for="(item, index) in source", :key="index" v-if="!isLoading")
        td(v-for="(column, columnIndex) in columns", :key="columnIndex")
          slot(v-if="column.customField" :name="column.name" :data="item")
          span(v-else) {{ formatter(source[index], column, item[column], index) }}
    tbody(v-else, class="text-center" v-loading="isLoading" element-loading-background="rgba(0, 0, 0, 0)")
      tr.no-data(v-if="!isLoading")
        td.no-data(:colspan="columns.length")
          NoData
</template>
<script>
import NoData from '~/components/noData';

export default {
  name: 'TableList',
  components: {
    NoData,
  },
  inject: ['isRotate'],
  props: {
    source: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    formatter: {
      type: Function,
      default: () => {},
    },
    customClass: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import '@/components/TableList/index.scss';
</style>
