import request from '~/utils/tc-request';

export const searchMembber = (data) => request.post('/service/member/searchMember', data);
export const getGiftList = (data) => request.post('/service/gift/getList', data);
export const giveGift = (data) => request.post('/service/gift', data, { headers: { NoTraceKey: true } });
export const acceptGift = (data) => request.post('/service/gift/accept', data);
export const refuseGift = (data) => request.post('/service/gift/refuse', data);

export default {
  searchMembber,
  getGiftList,
  giveGift,
  acceptGift,
  refuseGift,
};
